<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card">
        <div class="card-header">
          <h4><i class="fa fa-edit"></i> تعديل مشرف</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="">اسم المستخدم</label>
            <input type="text" class="form-control" v-model="username" />
          </div>
          <div class="form-group">
            <label for="">الجوال</label>
            <input type="text" class="form-control" v-model="phone" />
          </div>
          <div class="form-group">
            <label for="">كلمة المرور</label>
            <input type="text" class="form-control" v-model="password" />
          </div>
          <br />
          <p>
            الأماكن <a @click="all('.l1')"><u>تحديد الكل</u></a>
          </p>
          <div class="row">
            <div
              class="col-12 col-lg-4 g"
              v-for="place in places"
              :key="place._id"
            >
              <b-form-checkbox
                checked="true"
                name="check-button"
                class="l1"
                switch
                inline
                :value="place._id"
                v-model="rules"
              >
                {{ place.title }}
              </b-form-checkbox>
            </div>
          </div>
          <br />
          <p>
            الاجهزة <a @click="all('.l2')"><u>تحديد الكل</u></a>
          </p>
          <div class="row">
            <div
              class="col-12 col-lg-6 g"
              v-for="place in devices"
              :key="place._id"
            >
              <b-form-checkbox
                checked="true"
                class="l2"
                name="check-button"
                switch
                inline
                :value="place._id"
                v-model="rules"
              >
                {{ place.title }}
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-success" @click="add()">
              تعديل المشرف <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function makeid(length) {
  var result = "";
  var characters = "abcdefghkmnpqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

import { BFormCheckbox } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormCheckbox,
    ToastificationContent,
  },
  created() {
    var g = this;
    $.post(api + "/console/devices/list", {
      jwt: user.jwt,
    }).then(function (r) {
      g.devices = r.response;
    });
    $.post(api + "/console/places/list", {
      jwt: user.jwt,
    }).then(function (r) {
      g.places = r.response;
    });
    $.post(api + "/console/admins/admin", {
      jwt: user.jwt,
      id: window.location.href.split("/edit/")[1],
    }).then(function (r) {
      r = r.response;
      g.rules = r.rules;
      g.username = r.username;
      g.password = r.password;
      g.phone = r.phone;
    });
  },
  data() {
    return {
      username:
        makeid(3) + Math.random().toString().split(".")[1].substring(0, 2),
      password:
        makeid(1) + Math.random().toString().split(".")[1].substring(0, 4),
      phone: null,
      devices: [],
      places: [],
      rules: [],
    };
  },
  methods: {
    all(id) {
      $(id).each(function () {
        $(this).find("input").click();
      });
    },
    add() {
      var g = this;
      if (g.username) {
        $.post(api + "/console/admins/edit", {
          jwt: user.jwt,
          username: g.username,
          password: g.password,
          id: window.location.href.split("/edit/")[1],
          phone: g.phone,
          rules: g.rules,
        }).then(function (r) {
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        });
      }
    },
  },
};
</script>

<style>
</style>